/* eslint-disable @typescript-eslint/interface-name-prefix */
import classNames from 'classnames'
import React from 'react'
import styled from 'styled-components'
import NewPromo from '../../assets/images/promaid/icons/new-promo.svg'

const Container = styled.div`
  position: relative;
  margin: auto 0;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  scroll-snap-align: start;
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
    opacity: 0.85;
  }
`

const SpacialContainer = styled.div`
  display: none;
  position: absolute;
  width: 101px;
  margin-top: -25px;
  margin-left: -25px;
  .spacial-shadow-container {
    background: #6cbd45;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 0px 100px 100px 0px;
  }
  @media (max-width: 1024px) {
    width: 70px;
    margin-top: -15px;
    margin-left: -15px;
  }
`

const defaultTheme = styled.div``

const GreenBgButton = {
  background: '#6CBD45'
}

const RedBgButton = {
  background: '#FC0909'
}

const GreenOtherDetailButton = {
  display: 'inline'
  // margin: 4
}

const SpacialPromo = {
  display: 'inline'
}

const InActivePacket = {
  display: 'none'
}

const ActivePacket = {
  display: 'inline'
}

interface IProps {
  packet: {
    title: string
    name: string
    descriptionTop: string
    descriptionBottom: string
    price: string
    logo: string
    theme: string
    detail: string
  }
}

const PacketsLPCard: React.FC<IProps> = props => {
  const { packet } = props
  let selectTextColor = defaultTheme
  let showTitleService = defaultTheme
  let showSpacialPromo = defaultTheme
  let showDescription = defaultTheme
  let showListSpSmall = InActivePacket
  let showListSpLarge = defaultTheme
  let selectBgButton = defaultTheme
  let selectSpSmallDetailButton = defaultTheme
  let selectSpLargeDetailButton = defaultTheme
  let selectDetailButton = defaultTheme

  if (packet.theme === 'sp-small') {
    selectTextColor = InActivePacket
    showTitleService = ActivePacket
    showSpacialPromo = SpacialPromo
    showDescription = InActivePacket
    showListSpSmall = ActivePacket
    showListSpLarge = InActivePacket
    selectBgButton = RedBgButton
    selectSpSmallDetailButton = ActivePacket
    selectSpLargeDetailButton = InActivePacket
    selectDetailButton = InActivePacket
  } else if (packet.theme === 'sp-large') {
    selectTextColor = InActivePacket
    showTitleService = ActivePacket
    showSpacialPromo = SpacialPromo
    showDescription = InActivePacket
    showListSpSmall = InActivePacket
    showListSpLarge = ActivePacket
    selectBgButton = RedBgButton
    selectSpSmallDetailButton = InActivePacket
    selectSpLargeDetailButton = ActivePacket
    selectDetailButton = InActivePacket
  } else if (packet.theme === 'green') {
    selectTextColor = InActivePacket
    showTitleService = ActivePacket
    showSpacialPromo = InActivePacket
    showDescription = ActivePacket
    showListSpSmall = InActivePacket
    showListSpLarge = InActivePacket
    selectBgButton = GreenBgButton
    selectSpSmallDetailButton = InActivePacket
    selectSpLargeDetailButton = InActivePacket
    selectDetailButton = ActivePacket
  }
  return (
    <Container>
      <div className="flex flex-col w-full gap-1 bg-white border-white rounded-lg button-line justify-self-center">
        <div className="flex-shrink-0 block mb-3 button-line">
          <img className="w-full rounded-t-lg button-line" src={packet.logo} alt="packet promo" />
        </div>
        <SpacialContainer className="button-line" style={showSpacialPromo}>
          <img className="w-full rounded-t-lg button-line" src={NewPromo} alt="new promo" />
        </SpacialContainer>
        <div className="flex flex-col justify-center my-1 text-center button-line lg:my-2 xl:my-4">
          <div className="flex flex-col justify-center h-16 -mt-4 text-sm button-line md:text-base lg:text-lg">
            <div className="button-line" style={selectTextColor}>
              {packet.title}
            </div>
            <div>
              <p className="pb-2 text-lg button-line md:text-xl lg:text-2xl" style={selectTextColor}>
                {packet.name}
              </p>
            </div>
            <div className="text-base text-blue-200 button-line md:text-lg lg:text-xl" style={showTitleService}>
              <p className="-mt-3 xl:-mt-4 button-line">{packet.title}</p>
              <p className="button-line">{packet.name}</p>
            </div>
          </div>
          <div className="h-12 text-sm button-line lg:text-base" style={showDescription}>
            <p className="button-line">{packet.descriptionTop}</p>
            <p className="button-line">{packet.descriptionBottom}</p>
          </div>
        </div>
        <div className="flex flex-col -my-1 text-xs lg:-my-2 xl:-my-2 button-line lg:text-sm xl:text-sm" style={showListSpSmall}>
          <div className="flex justify-center pl-4 -mt-4 -mb-3 lg:-mt-3 lg:-mb-2 button-line">
            <ul className="list-disc button-line">
              <li className="text-xs leading-relaxed lg:text-sm button-line">เครื่องพ่นน้ำยาฆ่าเชื้อไร้สาย 1 เครื่อง</li>
              <li className="text-xs leading-relaxed lg:text-sm button-line">น้ำยาฆ่าเชื้อ 500 ml</li>
              <li className="text-xs leading-relaxed lg:text-sm button-line">เครื่องพ่นนาโนฆ่าเชื้อพกพา 1 เซต</li>
              <li className="text-xs leading-relaxed lg:text-sm button-line">กระบอก Syringe ขนาด 5 ml 1 ชิ้น</li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col -mt-1 -mb-2 text-xs lg:-mb-3 button-line lg:text-sm xl:text-sm" style={showListSpLarge}>
          <div className="flex justify-center pl-4 -mt-4 -mb-3 lg:-mt-3 lg:-mb-2 button-line">
            <ul className="list-disc button-line">
              <li className="text-xs leading-relaxed lg:text-sm button-line">เครื่องพ่นละอองฝอย 1 เครื่อง</li>
              <li className="text-xs leading-relaxed lg:text-sm button-line">น้ำยาฆ่าเชื้อ 2 แกลลอน (รวม 4L)</li>
              <li className="text-xs leading-relaxed lg:text-sm button-line">เครื่องพ่นฆ่าเชื้อพกพา 10 เซต</li>
              <li className="text-xs leading-relaxed lg:text-sm button-line">ชุด PPE 2 ชุด</li>
            </ul>
          </div>
        </div>
        <div className="mt-6 button-line">
          <div className="py-2 text-center border border-transparent button-line rounded-b-md" style={selectBgButton}>
            <p className="text-xl text-white button-line" style={selectSpSmallDetailButton}>
              <span className="text-3xl font-medium button-line">{packet.price} </span>
              บาท
            </p>
            <div className="button-line" style={selectSpLargeDetailButton}>
              <p className="py-1 pb-2 text-xl text-white sm:pb-0 md:pb-1 button-line">
                <span className="text-base font-light button-line md:text-xl">สอบถามเพิ่มเติม </span>
                คลิก !
              </p>
            </div>
            <div className="button-line" style={selectDetailButton}>
              <p className="py-1 text-xl text-white button-line">
                <span className="text-base font-light button-line md:text-xl">{packet.detail}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default PacketsLPCard
