/* eslint-disable import/no-unresolved */
import * as React from 'react'
import NewSchool from '../components/new-school'
// import { Link } from 'gatsby'
import Promaid from '../components/promaid'

import OldSchool from '../components/old-school'
import IndexLayout from '../layouts'
import FBMessenger from '../components/common/FBMessenger'

const IndexPage = () => (
  <IndexLayout>
    <Promaid />
    {/* <div className="hidden sm:block">
      <OldSchool />
    </div>
    <NewSchool /> */}
    {/* <Page>
      <Container>
        <h1>Hi people</h1>
        <p>Welcome to your new Gatsby site.</p>
        <p>Now go build something great.</p>
        <Link to="/page-2/">Go to page 2</Link>
      </Container>
    </Page> */}
    <FBMessenger />
  </IndexLayout>
)

export default IndexPage
