import { useEffect, useRef } from 'react'

interface IOptions {
  navMenu?: boolean
}

const useOnClickOutside = ({ enabled = true, handler, options }: { enabled?: boolean; handler: Function; options?: IOptions }) => {
  const targetElementRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!targetElementRef.current || (event.target && targetElementRef.current.contains(event.target as Node))) return
      if (options?.navMenu) {
        const menusString = (targetElementRef.current as any).dataset.currentMenus
        const menus = menusString.split(',').filter(menu => menu.length > 0)
        handler(menus)
      } else {
        handler()
      }
    }
    if (enabled) {
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
    } else {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [enabled])
  return targetElementRef
}
export default useOnClickOutside
