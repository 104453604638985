import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import tw from 'twin.macro'
import Line from '../../assets/images/promaid/icons/line-button.svg'

const Button = styled.button<{ ghost?: boolean }>`
  ${tw`px-2 py-2 border border-transparent sm:px-3 sm:py-3 md:px-6 md:py-3 xl:px-6 xl:py-3`}
`

export const PrimaryButton = styled(Button)`
  ${tw`flex items-center text-base leading-none border cursor-pointer sm:text-lg md:text-2xl xl:text-3xl border-secondary rounded-xl hover:opacity-80 hover:border-secondary bg-secondary focus:outline-none`}
  ${props => (props.ghost ? tw`text-white` : tw`text-white bg-secondary`)}
`

const BookingButton: React.FC = () => {
  return (
    <>
      <PrimaryButton
        onClick={() => {
          window.open('https://bit.ly/2Pln3bV', '_blank')
        }}
        className="button-line"
      >
        <div className="flex flex-row items-center justify-between -my-1 button-line">
          <p className="button-line">จองแม่บ้านมืออาชีพ </p>
          <div className="flex justify-end w-10 h-10 pl-2 button-line md:w-auto md:h-auto">
            <img src={Line} alt="line" />
          </div>
        </div>
      </PrimaryButton>
    </>
  )
}

export default BookingButton
