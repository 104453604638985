import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Menus, onClickMenu } from './NavBar'
import ReviewPromaid from '../../assets/images/promaid/review.svg'
import MenuOption1 from '../../assets/images/promaid/option1.png'
import MenuOption2 from '../../assets/images/promaid/option2.png'
import MenuOption3 from '../../assets/images/promaid/option3.png'
import ReviewCard from '../common/ReviewCard'
import ModalReview from '../common/ModalReview'
import Slider from '../common/SliderComponent'

const reviews = [
  {
    name: 'คุณแอนท์',
    subname: 'ออฟฟิศ, เขตวัฒนา',
    shortText: 'แม่บ้านตั้งใจทำงานดีมากค่ะ ทำงานตลอดไม่มีหยุดเลย ตรงเวลาสม่ำเสมอ สุภาพ ไว้วางใจได้ มืออาชีพมากจริงๆ ประทับใจมาก',
    text: 'แม่บ้านตั้งใจทำงานดีมากค่ะ ทำงานตลอดไม่มีหยุดเลย ตรงเวลาสม่ำเสมอ สุภาพ ไว้วางใจได้ มืออาชีพมากจริงๆ ประทับใจมาก',
    score: 5,
    type: 'บริการทำความสะอาด'
  },
  {
    name: 'คุณอรวรรณ',
    subname: 'บ้านเดี่ยว, เขตคลองสามวา',
    shortText: 'แม่บ้าน ProMaid ทำงานเรียบร้อยดี พนักงานจัดตารางนัดก็บริการดี สะดวกค่ะ คือบริการดีมากค่ะ',
    text: 'แม่บ้าน ProMaid ทำงานเรียบร้อยดี พนักงานจัดตารางนัดก็บริการดี สะดวกค่ะ คือบริการดีมากค่ะ',
    score: 5,
    type: 'บริการทำความสะอาด'
  },
  {
    name: 'คุณศรินทิพย์',
    subname: 'ออฟฟิศ, นนทบุรี',
    shortText: 'ดีค่ะ แนะนำให้ใช้บริการ เพื่อความปลอดภัยต่อเราเอง และผู้อื่น ราคาสมเหตุสมผล พนักงานบริการดีค่ะ',
    text: 'ดีค่ะ แนะนำให้ใช้บริการ เพื่อความปลอดภัยต่อเราเอง และผู้อื่น ราคาสมเหตุสมผล พนักงานบริการดีค่ะ',
    score: 5,
    type: 'บริการฉีดพ่นฆ่าเชื้อ'
  },
  {
    name: 'คุณเมธาวดี',
    subname: 'บ้านเดี่ยว, เขตประเวศ',
    shortText: 'ประทับใจค่า แม่บ้านทำงานดี บ้านสะอาดเรียบร้อยดีมากค่ะ',
    text: 'ประทับใจค่า แม่บ้านทำงานดี บ้านสะอาดเรียบร้อยดีมากค่ะ',
    score: 5,
    type: 'บริการทำความสะอาด'
  },
  {
    name: 'คุณวาริธร',
    subname: 'บ้านเดี่ยว, เขตบางกะปิ',
    shortText: 'แม่บ้านมาตรงต่อเวลาดี และเหมือนได้รับการฝึกอบรมมาดี เพียงอธิบายสั้นๆ ก็ทำงานได้ ดูมีความกระตือรือร้นในการทำงานดีค่ะ',
    text: 'แม่บ้านมาตรงต่อเวลาดี และเหมือนได้รับการฝึกอบรมมาดี เพียงอธิบายสั้นๆ ก็ทำงานได้ ดูมีความกระตือรือร้นในการทำงานดีค่ะ',
    score: 5,
    type: 'บริการทำความสะอาด'
  },
  {
    name: 'คุณจีรวรรณ',
    subname: 'บ้านเดี่ยว, เขตพญาไท',
    shortText:
      'แม่บ้านดีมากค่ะ​ น่ารัก​ สุภาพ​ ไว้ใจได้​ ไม่​ค่อยต้องบอกอะไรเป็นพิเศษ​ ทำได้สะอาด​เท่าที่ต้องการอยู่แล้ว​ จุดไหนที่ต้องการเพิ่มเติม​ก็แค่แจ้งไว้ ก็จะเน้นทํา​ความ​สะอาด​ให้​',
    text:
      'แม่บ้านดีมากค่ะ​ น่ารัก​ สุภาพ​ ไว้ใจได้​ ไม่​ค่อยต้องบอกอะไรเป็นพิเศษ​ ทำได้สะอาด​เท่าที่ต้องการอยู่แล้ว​ จุดไหนที่ต้องการเพิ่มเติม​ก็แค่แจ้งไว้ ก็จะเน้นทํา​ความ​สะอาด​ให้​',
    score: 5,
    type: 'บริการทำความสะอาด'
  },
  {
    name: 'คุณขวัญสุดา',
    subname: 'คอนโดมิเนี่ยม, เขตวัฒนา',
    shortText: 'ใช้บริการแม่บ้าน ProMaid มานาน แม่บ้านน่ารัก มารยาทดี ทำบ้านสะอาดเรียบร้อยค่ะ ไม่มีข้อติ',
    text: 'ใช้บริการแม่บ้าน ProMaid มานาน แม่บ้านน่ารัก มารยาทดี ทำบ้านสะอาดเรียบร้อยค่ะ ไม่มีข้อติ',
    score: 5,
    type: 'บริการทำความสะอาด'
  },
  {
    name: 'คุณพรจิตต์',
    subname: 'ออฟฟิศ, เขตห้วยขวาง',
    shortText: 'มั่นใจปลอดภัย เครื่องมือได้มาตรฐาน',
    text: 'มั่นใจปลอดภัย เครื่องมือได้มาตรฐาน',
    score: 5,
    type: 'บริการฉีดพ่นฆ่าเชื้อ'
  },
  {
    name: 'คุณกาญจนาภรณ์',
    subname: 'ออฟฟิศ, นนทบุรี',
    shortText: 'รู้สึกมั่นใจปลอดภัยขึ้น ทั้งเราเอง และลูกค้าที่มาใช้บริการ',
    text: 'รู้สึกมั่นใจปลอดภัยขึ้น ทั้งเราเอง และลูกค้าที่มาใช้บริการ',
    score: 5,
    type: 'บริการฉีดพ่นฆ่าเชื้อ'
  },
  {
    name: 'คุณปิยนุช',
    subname: 'ออฟฟิศ, เขตพระโขนง',
    shortText: 'ตรงเวลา แม่บ้านได้รับการเทรนด์มาดี ใช้บริการมาเกือบ 10 ปี ยังไม่เปลี่ยนใจค่ะ',
    text: 'ตรงเวลา แม่บ้านได้รับการเทรนด์มาดี ใช้บริการมาเกือบ 10 ปี ยังไม่เปลี่ยนใจค่ะ',
    score: 5,
    type: 'บริการทำความสะอาด'
  },
  {
    name: 'คุณกิตติพศ',
    subname: 'ทาวน์โฮม, อำเภอบางพลี',
    shortText: 'งานดีเหมือนเดิมครับ ไม่ผิดหวัง​ 👍👍',
    text: 'งานดีเหมือนเดิมครับ ไม่ผิดหวัง​ 👍👍',
    score: 5,
    type: 'บริการทำความสะอาด'
  },
  {
    name: 'คุณวันปิยะ',
    subname: 'บ้านเดี่ยว, อำเภอบางพลี',
    shortText: 'ชอบแม่บ้านมากๆครับ ดีมากที่เพื่อนแนะนำมา ชีวิตดีขึ้นเยอะครับ !',
    text: 'ชอบแม่บ้านมากๆครับ ดีมากที่เพื่อนแนะนำมา ชีวิตดีขึ้นเยอะครับ !',
    score: 5,
    type: 'บริการทำความสะอาด'
  }
]

const Title = styled.h3`
  font-size: 30px;
  line-height: 1.2;
  font-weight: 400;

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 1.5;
  }
`

const ReviewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;

  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    grid-gap: 2px;
  }
`

const ReviewCardContainer = styled.div`
  /* grid-gap: 1.5rem; */
  /* padding-left: 16px;
  padding-right: 16px; */
  /* margin-left: 100px;
  margin-right: 100px; */
  width: 1102px;
  background: #f2fcfe;

  /* @media (max-width: 600px) {
    width: 100%;
  } */
`

const Card = styled.div`
  align-self: center;
  height: 100%;
`

const Review = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectCard, setSelectCard] = useState(reviews[0])

  return (
    <div id={Menus.REVIEW} className="p-0">
      <Title className="p-8 text-center sm:p-12 text-primary">ทำไมคุณถึงต้องใช้บริการ ProMaid</Title>
      <ReviewContainer>
        <div className="flex justify-center px-8 lg:w-full lg:px-0 top-review-container">
          <img className="hidden xl:flex xl:visible" src={ReviewPromaid} alt="review promaid" />
          <div className="flex flex-col gap-2 md:gap-8 lg:mt-10 lg:mr-20">
            <div className="flex justify-center pl-4">
              <div className="flex flex-col justify-center">
                <div className="flex flex-row items-center gap-4 pb-4 sm:gap-6">
                  <div className="w-16 h-16 md:h-32 md:w-32">
                    <img src={MenuOption1} alt="menu 1" />
                  </div>
                  <p className="flex-1 pl-2 ml-4 text-xs font-normal text-blue-300 sm:text-sm md:text-2xl">
                    ด้วยบริษัทที่ผ่านงานโรงแรม
                    <br />
                    ระดับ 5 ดาว
                  </p>
                </div>
                <div className="flex flex-row items-center gap-4 pb-4 sm:gap-6">
                  <div className="w-16 h-16 md:h-32 md:w-32">
                    <img src={MenuOption2} alt="menu 2" />
                  </div>
                  <p className="flex-1 pl-2 ml-4 text-xs font-normal text-blue-300 sm:text-sm md:text-2xl">
                    ได้รับการไว้วางใจจากลูกค้า
                    <br />
                    มากว่า 18 ปี
                  </p>
                </div>
                <div className="flex flex-row items-center gap-4 pb-4 sm:gap-6">
                  <div className="w-16 h-16 md:h-32 md:w-32">
                    <img src={MenuOption3} alt="menu 3" />
                  </div>
                  <p className="flex-1 pl-2 ml-4 text-xs font-normal text-blue-300 sm:text-xl md:text-2xl">
                    รวมอุปกรณ์ และประกันคุ้มครอง <br /> ความเสียหาย <span className="text-xs text-gray-200 sm:text-lg">(จ่ายตามจริง)</span>
                  </p>
                </div>
              </div>
            </div>
            <img className="flex visible xl:hidden" src={ReviewPromaid} alt="review promaid" />
          </div>
        </div>
      </ReviewContainer>

      <div className="pb-16 bg-light_blue">
        <Title className="py-10 text-center sm:py-4 md:py-6 lg:py-8 text-primary">ประสบการณ์จากผู้ใช้</Title>
        {/* <ReviewCardContainer> */}
        <div className="relative flex justify-center w-full max-w-screen-xl px-4 mx-auto lg:px-16 xl:px-8">
          <Slider className="w-full" tabletSize={45}>
            {reviews.map((review, i) => (
              <Card
                key={review.name}
                onClick={() => {
                  setSelectCard(review)
                  setIsOpen(true)
                }}
              >
                <ReviewCard review={{ ...review, text: review.shortText }} />
              </Card>
            ))}
          </Slider>
        </div>
        {/* </ReviewCardContainer> */}
      </div>
    </div>
  )
}

export default Review
