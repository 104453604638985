/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import styled from '@emotion/styled'

import PersonIcon from '../../assets/images/promaid/icons/person.svg'
import SchedueIcon from '../../assets/images/promaid/icons/schedue.svg'
import ClockIcon from '../../assets/images/promaid/icons/clock.svg'
import Packet4hr from '../../assets/images/promaid/packet4hr.jpg'
import Packet8hr from '../../assets/images/promaid/packet8hr.jpg'
import Packet3month from '../../assets/images/promaid/packet3month.jpg'
import Packet6month from '../../assets/images/promaid/packet6month.jpg'
import NumberOne from '../../assets/images/promaid/icons/number1.svg'
import NumberTwo from '../../assets/images/promaid/icons/number2.svg'
import NumberThree from '../../assets/images/promaid/icons/number3.svg'

import { Menus } from './NavBar'

import Slider from '../common/SliderComponent'
import PacketsCard from '../common/PacketsCard'
import PromoPacket from '../../assets/images/promaid/packet2hr.jpg'
import SpacialPacket from '../../assets/images/promaid/special-promo.jpg'
import ServicePacket from '../../assets/images/promaid/servicePacket.png'
import SpPacket from '../../assets/images/promaid/sp-packet.png'

import Line from '../../assets/images/promaid/icons/line-button.svg'
import { PrimaryButton } from '../common/BookingButton'

const servicePackets = [
  {
    title: '',
    name: 'บริการฉีดพ่นฆ่าเชื้อ',
    description: '',
    price: '2,500',
    logo: SpacialPacket,
    theme: 'special-promo',
    detail: 'เริ่มต้นเพียง'
  },
  {
    title: 'อุปกรณ์ฆ่าเชื้อไร้สาย',
    name: 'ด้วยตัวคุณเอง พร้อมน้ำยา',
    description: '',
    price: '2,599',
    logo: SpPacket,
    theme: 'sp',
    detail: 'เริ่มต้นเพียง'
  },
  {
    title: 'ชุดเครื่องพ่นฆ่าเชื้อ',
    name: 'พร้อมอุปกรณ์',
    description: '',
    price: 'test',
    logo: ServicePacket,
    theme: 'clean',
    detail: 'เริ่มต้นเพียง'
  }
]

const packets = [
  {
    title: 'บริการทำความสะอาด',
    name: '2 ชั่วโมง',
    description: 'แม่บ้าน 1 คน',
    price: '590',
    logo: PromoPacket,
    theme: 'promo',
    detail: ''
  },
  {
    title: 'บริการทำความสะอาด',
    name: '4 ชั่วโมง',
    description: 'แม่บ้าน 1 คน',
    price: '990',
    logo: Packet4hr,
    theme: 'light'
  },
  {
    title: 'บริการทำความสะอาด',
    name: '8 ชั่วโมง',
    description: 'แม่บ้าน 1 คน 8 ชม. / 2 คน 4 ชม.',
    price: '1,790',
    logo: Packet8hr,
    theme: 'light',
    detail: ''
  },
  {
    title: 'บริการทำความสะอาด',
    name: '(4 ครั้ง / 3 เดือน)',
    description: 'แม่บ้าน 1 คน / 4 ชม.',
    price: '3,400',
    logo: Packet3month,
    theme: 'dark',
    detail: ''
  },
  {
    title: 'บริการทำความสะอาด',
    name: '(12 ครั้ง / 6 เดือน)',
    description: 'แม่บ้าน 1 คน / 4 ชม.',
    price: '10,900',
    logo: Packet6month,
    theme: 'dark',
    detail: ''
  }
]

const Title = styled.h3`
  font-size: 30px;
  line-height: 1.2;
  font-weight: 400;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 1.5;
  }

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 1.5;
  }
`

const DetailText = styled.p`
  color: #5c5c69;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 14px;
  }
`

const HowBooking = styled.div`
  .booking-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 12px;
    @media (min-width: 1240px) {
      grid-gap: 50px;
    }
    @media (max-width: 600px) {
      grid-gap: 2px;
    }
  }
  .detail-booking-container {
    display: grid;
    grid-template-columns: fit-content(100%) 1fr;
    grid-gap: 12px;
    justify-content: center;
  }

  @media (max-width: 600px) {
    .booking-container {
      display: grid;
      grid-template-columns: fit-content(100%) 1fr;
      grid-gap: 12px;
    }
  }
`

const HeaderContainer = styled.div`
  position: relative;
  height: 200px;
  display: flex;
  justify-content: center;
`

const FootContainer = styled.div`
  position: relative;
  /* width: 100%; */
  height: 67px;
  display: flex;
  justify-content: center;
`

const Card = styled.div`
  align-self: center;
  height: 100%;
  padding: 1px;
`

const Packet: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectCard, setSelectCard] = useState(packets[0])
  const [selectServiceCard, setSelectServiceCard] = useState(servicePackets[0])

  return (
    <>
      <div className="relative bg-blue-100 head-container" style={{ height: '200px' }} />
      <div className="relative flex flex-col items-center py-16 mx-0 -mt-56" style={{ top: '100%' }}>
        <div className="w-full max-w-screen-xl px-4 lg:px-8">
          <div className="flex flex-col p-4 text-center bg-white shadow-md rounded-xl">
            <div className="py-2 text-center sm:py-4">
              <Title className="justify-center hidden text-primary md:flex">จองแม่บ้านง่ายๆ ไม่ต้องโหลดแอพ พร้อมบริการฉีดพ่นฆ่าเชื้อ</Title>
              <Title className="flex justify-center text-primary md:hidden">
                <p className="text-base md:text-lg">
                  จองแม่บ้านง่ายๆ ไม่ต้องโหลดแอพ
                  <br />
                  พร้อมบริการฉีดพ่นฆ่าเชื้อ
                </p>
              </Title>
            </div>
            <div className="py-4">
              <HowBooking>
                <div className="flex justify-around">
                  <div className="px-18 booking-container">
                    <div className="flex-col hidden gap-1 sm:flex sm:justify-center sm:items-center">
                      <img className="object-center mx-16" src={PersonIcon} alt="Person" />
                      <div className="pt-2">
                        <p className="text-sm font-normal leading-snug text-blue-300 md:text-xl xl:text-2xl">ทัก LINE หรือ</p>
                        <DetailText className="text-xs leading-snug md:text-lg xl:text-xl">Facebook Messenger</DetailText>
                      </div>
                    </div>
                    <div className="flex-col hidden gap-1 sm:flex sm:justify-center sm:items-center">
                      <img className="object-center sm:mx-16" src={SchedueIcon} alt="Schedue" />
                      <div className="pt-2">
                        <p className="text-sm font-normal leading-snug text-blue-300 md:text-xl xl:text-2xl">แจ้งที่อยู่ ขนาดพื้นที่</p>
                        <DetailText className="text-xs leading-snug md:text-lg xl:text-xl">วัน และเวลา ที่ต้องการใช้บริการ</DetailText>
                      </div>
                    </div>
                    <div className="flex-col hidden gap-1 sm:flex sm:justify-center sm:items-center">
                      <img className="object-center sm:mx-16" src={ClockIcon} alt="Clock" />
                      <div className="pt-2">
                        <p className="text-sm font-normal leading-snug text-blue-300 md:text-xl xl:text-2xl">ชำระเงิน</p>
                        <DetailText className="text-xs leading-snug md:text-lg xl:text-xl">และรอรับบริการที่บ้านได้เลย</DetailText>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div className="block sm:hidden sm:detail-booking-container">
                    <div className="flex flex-row h-12 mb-4">
                      <img src={NumberOne} alt="one" />
                      <img className="w-10 mx-4 sm:mx-16" src={PersonIcon} alt="Person" />
                      <p className="flex flex-col justify-center gap-1 text-sm font-normal text-left text-blue-300">
                        ทัก LINE หรือ{' '}
                        <span className="visible text-xs sm:hidden">
                          <DetailText>Facebook Messenger</DetailText>
                        </span>
                      </p>
                    </div>
                    <div className="flex flex-row h-12 mb-4">
                      <img src={NumberTwo} alt="two" />
                      <img className="w-10 mx-4 sm:w-auto sm:mx-16" src={SchedueIcon} alt="Schedue" />
                      <p className="flex flex-col justify-center gap-1 text-sm font-normal text-left text-blue-300">
                        แจ้งที่อยู่ ขนาดพื้นที่{' '}
                        <span className="visible text-xs sm:hidden">
                          <DetailText>วัน และเวลา ที่ต้องการใช้บริการ</DetailText>
                        </span>
                      </p>
                    </div>
                    <div className="flex flex-row h-12 mb-4">
                      <img src={NumberThree} alt="three" />
                      <img className="w-10 mx-4 sm:w-auto sm:mx-16" src={ClockIcon} alt="Clock" />
                      <p className="flex flex-col justify-center gap-1 text-sm font-normal text-left text-blue-300">
                        ชำระเงิน{' '}
                        <span className="visible text-xs sm:hidden">
                          <DetailText>และรอรับบริการที่บ้านได้เลย</DetailText>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </HowBooking>
            </div>
          </div>
          <div className="mb-6" id={Menus.PACKET} />
        </div>
        <div className="w-full max-w-screen-xl px-0 sm:px-4 lg:px-8">
          <Title className="py-4 text-center md:py-6 xl:py-10 text-primary">แพ็กเกจทำความสะอาดสุดคุ้ม</Title>
          <div className="px-2 my-2 button-line">
            <Slider className="w-full button-line" scrollSize={4} tabletSize={40} mobileSize={83}>
              {packets.map(packet => (
                <Card
                  className="button-line"
                  key={packet.name}
                  onClick={() => {
                    setSelectCard(packet)
                    setIsOpen(true)
                    window.open('https://bit.ly/2Pln3bV', '__blank')
                  }}
                >
                  <div className="mt-4 ml-2">
                    <PacketsCard packet={{ ...packet }} />
                  </div>
                </Card>
              ))}
            </Slider>
          </div>
          <div className="mt-10 text-lg font-medium text-center text-gray-200 sm:text-2xl">เลือกแพ็กเกจที่ตรงใจ ทักถามเราได้เลย</div>
          <div className="flex justify-center m-4">
            <PrimaryButton
              onClick={() => {
                window.open('https://bit.ly/2Pln3bV', '_blank')
              }}
              className="button-line "
            >
              <div className="flex flex-row items-center justify-between -my-1 button-line">
                <p className="button-line">จองเลย </p>
                <div className="flex justify-end w-10 h-10 pl-2 button-line md:w-auto md:h-auto">
                  <img src={Line} alt="line" />
                </div>
              </div>
            </PrimaryButton>
          </div>
          <div className="text-base font-normal text-center text-gray-200 sm:text-xl">จองคิวล่วงหน้า 24 ชม.</div>
        </div>
      </div>
    </>
  )
}

export default Packet
