import React from 'react'
import styled from '@emotion/styled'
import { Menus } from './NavBar'
import BookingButton from '../common/BookingButton'
import BgAbout1 from '../../assets/images/promaid/bg-about1.jpg'
import BgAbout2 from '../../assets/images/promaid/bg-about2.jpg'

const Container = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`

const Title = styled.h3`
  font-size: 30px;
  line-height: 1.2;
  font-weight: 400;

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 1.5;
    justify-content: center;
  }
`

const Name = styled.h3`
  font-size: 30px;
  line-height: 1.2;
  font-weight: 400;

  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 1.5;
    justify-content: center;
  }
`

const Subtitle = styled.h2`
  font-size: 24px;
  font-weight: 300;

  @media (max-width: 1024px) {
    font-size: 18px;
    width: auto;
  }

  @media (max-width: 769px) {
    font-size: 16px;
    width: auto;
  }

  @media (max-width: 640px) {
    font-size: 14px;
    width: auto;
  }

  @media (max-width: 321px) {
    font-size: 12px;
    width: auto;
  }
`

const Gradient1 = styled.div`
  position: absolute;
  background: linear-gradient(90deg, #ffffff 28.82%, #ffffff 60.16%, rgba(255, 255, 255, 0) 82.44%);
  height: 100%;
  width: 80%;

  @media (max-width: 600px) {
    display: hidden;
  }
`

const Gradient2 = styled.div`
  display: hidden;

  @media (max-width: 600px) {
    position: absolute;
    background: linear-gradient(180deg, #ffffff 28.82%, #ffffff 60.16%, rgba(255, 255, 255, 0) 82.44%);
    height: 105%;
    width: 100%;
    right: 0px;
    left: 0px;
    top: 0px;
  }
`

const AboutUs: React.FC = () => {
  return (
    <Container className="relative z-0 flex items-center">
      <img className="absolute top-0 right-0 hidden w-full h-full sm:flex" src={BgAbout1} alt="bg-about-1" />
      <Gradient1 />
      <img className="absolute inset-x-0 bottom-0 flex flex-row w-full h-full sm:hidden" src={BgAbout2} alt="bg-about-2" />
      <Gradient2 />
      <div className="relative z-10 w-full -mt-6 sm:-mt-0">
        <div id={Menus.ABOUTUS} className="mb-6" />
        <Title className="p-8 text-center sm:mb-6 text-primary">เกี่ยวกับเรา</Title>
        <div className="w-full px-2 sm:w-1/2 sm:mx-20">
          <Name className="font-normal leading-snug text-center sm:pt-10 text-primary">“บริษัท แม่บ้านมืออาชีพ”</Name>
          <div className="flex justify-center w-full">
            <Subtitle className="font-normal text-center text-gray-200 sm:py-6">
              มั่นใจในสินค้าและบริการของเรา
              <br />
              ทั้ง บริการฉีดพ่นฆ่าเชื้อโรค บริการแม่บ้านทำความสะอาด
              <br />
              และ ชุดอุปกรณ์ฉีดพ่นฆ่าเชื้อ (เครื่องพ่นละอองฝอย,
              <br />
              น้ำยาฆ่าเชื้อ, เครื่องพ่นฆ่าเชื้อพกพา และ ชุด PPE)
              <br />
              เพียงทักไลน์มาวันนี้
            </Subtitle>
          </div>
        </div>
        <div className="z-10 m-10 text-center sm:m-20">
          <Subtitle className="p-4 text-base sub-text sm:p-6 sm:text-2xl text-primary">
            สอบถามเขตการให้บริการ
            <br />
            ทักหาเราสิคะ
          </Subtitle>
          <div className="flex justify-center w-full">
            <BookingButton />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default AboutUs
