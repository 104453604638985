/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import Cover from './Cover'
import Contact from './Contact'
import Navbar, { Menus, onClickMenu } from './NavBar'
import Packet from './Packet'
import Review from './Review'
import AboutUs from './AboutUs'

import CoverImage from '../../assets/images/promaid/bg-cover.svg'
import Equipment from './Equipment'
import Disinfection from './Disinfection'

const mounse = keyframes`
  from {
    opacity: 1;
    top: 6px;
  }
  to {
    opacity: 0;
    top: 25px;
  }
`

const Mouse = styled.div`
  cursor: pointer;
  position: relative;
  width: 2rem;
  height: 3rem;
  border: 3px solid #fff;
  border-radius: 9999px;
  display: flex;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 99px;
    background: #fff;
    animation: ${mounse} 1.5s infinite;
  }
`

const CoverImg = styled.img`
  display: none;
  @media (max-width: 1440px) {
    display: flex;
    /* margin-top: 0px; */
  }
`

const Promaid = () => {
  const stickyRef = useRef<HTMLDivElement>(null)
  const [isActiveSticky, setIsActiveSticky] = useState(false)

  useEffect(() => {
    let stickyOffsetTop = 0
    function scrollCallback() {
      if (stickyRef.current) {
        stickyOffsetTop = stickyRef.current.offsetTop + 60
        if (window.pageYOffset >= stickyOffsetTop && !isActiveSticky) setIsActiveSticky(true)
        else if (window.pageYOffset < stickyOffsetTop) setIsActiveSticky(false)
      }
    }
    scrollCallback()
    if (stickyRef && stickyRef.current) {
      window.addEventListener('scroll', scrollCallback)
    }
    return () => window.removeEventListener('scroll', scrollCallback)
  }, []) // eslint-disable-line

  return (
    <div ref={stickyRef} className="w-full font-sans sm:absolute">
      <div className="" style={{ boxShadow: '0px -30px 81px -8px rgba(0,0,0,0.49)' }}>
        <Navbar isActiveSticky={isActiveSticky} />
        <CoverImg className="absolute w-screen justify-self-right" src={CoverImage} alt="cover" />
        <Cover />
        <Packet />
        <Disinfection />
        <Equipment />
        <Review />
        <AboutUs />
        <Contact />
      </div>
    </div>
  )
}

export default Promaid
