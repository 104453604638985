/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { Menus, onClickMenu } from './NavBar'
import PromaidBanner from '../../assets/images/promaid/homecare-promaid.jpg'
import FacebookContact from '../../assets/images/promaid/icons/contact-facebook.svg'
import LineContact from '../../assets/images/promaid/icons/contact-line.svg'
import LogoTicaNac from '../../assets/images/promaid/LOGO-TICA_NAC.svg'
import LogoISO from '../../assets/images/promaid/New-ISO-logo.jpg'
import NewLogo from '../../assets/images/promaid/new-iso.svg'

const Title = styled.h3`
  font-size: 30px;
  line-height: 1.2;
  font-weight: 400;

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 1.5;
  }
`

const Text = styled.p`
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 400;
  /* font-size: 18px; */
  line-height: 25px;
`

const ImgISO = styled.img`
  height: 89px;

  @media (max-width: 600px) {
  }
`

const Contact: React.FC = () => {
  return (
    <>
      <div id={Menus.CONTACT} className="relative w-full h-full mt-10">
        <Title className="py-6 text-center text-primary">ติดต่อเรา</Title>
        <div className="flex flex-col justify-around p-4 sm:flex-row sm:p-10">
          <div className="flex flex-col bg-white md:justify-start">
            <p className="font-normal text-blue-300 sm:text-xl">Address:</p>
            <Text className="text-sm text-gray-200 md:text-base">
              บริษัท แม่บ้านมืออาชีพ จำกัด
              <br /> เลขที่ 429/29 ถ.ลาดพร้าววังหิน แขวงลาดพร้าว
              <br /> เขตลาดพร้าว กรุงเทพมหานคร 10230
              <br />
              <a href="https://promaid.co.th" target="_blank">
                <Text className="text-primary">https://promaid.co.th/</Text>
              </a>
            </Text>
          </div>
          <div className="py-4 ml-0 sm:ml-16 sm:py-0 md:py-0">
            <div className="flex flex-col justify-end text-left bg-white">
              <div className="inset-y-0 right-0 flex flex-col">
                <div className="font-normal text-blue-300 sm:text-xl">Line:</div>
                <p className="text-gray-200 sm:text-lg">@promaid.pm</p>
              </div>
              <div className="pt-4 sm:mt-4 sm:pt-0">
                <div className="flex flex-row justify-center sm:justify-between">
                  <div
                    className="pr-2 sm:pr-3"
                    onClick={() => {
                      window.open('https://www.facebook.com/Professional.Maid', '_blank')
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <img className="button-facebook" src={FacebookContact} alt="facebook contact" />
                  </div>
                  <div
                    onClick={() => {
                      window.open('https://bit.ly/2Pln3bV', '_blank')
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <img className="button-line" src={LineContact} alt="line contact" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="mb-8 bg-white sm:ml-10">
              <div className="flex flex-col items-end -mt-0 sm:-mt-6">
                <div className="hidden sm:flex">
                  <img src={PromaidBanner} alt="promaid-banner" />
                </div>
                <div className="">
                  <img src={NewLogo} alt="new-logo" />
                  {/* <div className="flex flex-row justify-between">
                    <img src={LogoTicaNac} alt="logo-tica-nac" />
                    <ImgISO className="absolute right-2 sm:right-28" src={LogoISO} alt="logo-iso" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-2 py-4 text-center text-blue-300 bg-white border-t-2 border-blue-300 cursor-pointer lg:px-40 md:px-4">
          Copyright © 2021 Promaid
        </div>
      </div>
    </>
  )
}

export default Contact
