/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import classnames from 'classnames'
import { Transition } from '@tailwindui/react'
import useOnClickOutside from '../common/hooks/use-click-outside'
import { scrollToOffset } from '../../utils/dom'
import PromaidBanner from '../../assets/images/promaid/homecare-promaid.png'
import Line from '../../assets/images/promaid/icons/line.svg'
import HamburgerIcon from '../../assets/icons/icon-hamburger.svg'
import XIcon from '../../assets/icons/icon-x.svg'
import LINE_LOGO from '../../assets/images/promaid/line.png'

export enum Menus {
  HOME = 'home',
  SERVICE = 'cleanningservice',
  DISINFECTION = 'disinfectionservice',
  EQUIPMENT = 'disinfectionequipment',
  PACKET = 'packet',
  REVIEW = 'review',
  ABOUTUS = 'aboutus',
  CONTACT = 'contact'
}

const navHeight = 60

export const onClickMenu = (id: Menus, noOffset = false) => {
  scrollToOffset(id, noOffset ? 0 : -navHeight)
}

const Navbar: React.FC<{ isActiveSticky: boolean }> = ({ isActiveSticky }) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)
  const refMenuTablet = useOnClickOutside({ handler: () => setIsMenuExpanded(false) })

  const onClickMobileMenu = (id: Menus, noOffset = false) => {
    if (isMenuExpanded) {
      setIsMenuExpanded(false)
    }
    onClickMenu(id, noOffset)
  }
  return (
    <>
      <div
        className={classnames(
          { 'flex fixed top-0 w-full bg-white': isActiveSticky },
          { 'flex absolute w-full sm:justify-center justify-start bg-transparent': !isActiveSticky },
          'z-30 px-4 sm:justify-between sm:px-40 invisible lg:visible md:px-0 lg:px-5 xl:px-32 text-black items-center bg-transparent'
        )}
      >
        <div className="cursor-pointer" onClick={() => onClickMenu(Menus.SERVICE, true)}>
          <img className="w-full h-20 sm:w-auto " src={PromaidBanner} alt="promaid-banner" />
        </div>
        <div className="justify-end hidden space-x-20 sm:flex" style={{ width: '720px' }}>
          <div className="text-xl text-gray-300 cursor-pointer" onClick={() => onClickMenu(Menus.SERVICE, true)}>
            บริการทำความสะอาด
          </div>
          <div className="text-xl text-gray-300 cursor-pointer" onClick={() => onClickMenu(Menus.DISINFECTION)}>
            บริการฉีดพ่นฆ่าเชื้อ
          </div>
          <div className="text-xl text-gray-300 cursor-pointer" onClick={() => onClickMenu(Menus.EQUIPMENT)}>
            ชุดอุปกรณ์ฉีดพ่นฆ่าเชื้อ
          </div>
        </div>
        <div className="p-1">
          <div
            onClick={() => {
              window.open('https://bit.ly/2Pln3bV', '_blank')
            }}
            role="button"
            tabIndex={0}
          >
            <img className="hidden h-20 cursor-pointer button-line md:flex " src={Line} alt="line icon" />
          </div>
        </div>
      </div>
      <div
        className={classnames(
          { 'flex fixed top-0 w-full justify-between bg-white': isActiveSticky },
          { 'flex absolute w-full justify-between bg-transparent': !isActiveSticky },
          'z-30 h-16 visible lg:invisible'
        )}
      >
        <div onClick={() => onClickMenu(Menus.HOME, true)}>
          <img className="h-16 ml-4 w-30 sm:w-auto md:ml-10" src={PromaidBanner} alt="promaid-banner" />
        </div>
        <div className="h-16 mx-6 my-5" onClick={() => setIsMenuExpanded(!isMenuExpanded)}>
          <img className="w-6 text-white" src={HamburgerIcon} alt="hamburger" />
        </div>
        <Transition
          show={isMenuExpanded}
          enter="transition ease-in-out duration-200 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-200 transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
          className="absolute top-0 left-0 w-full transition-all bg-white"
        >
          <div ref={refMenuTablet} className="flex flex-row justify-between mb-4">
            <div className="h-16 mt-4 ml-4" onClick={() => onClickMobileMenu(Menus.SERVICE, true)}>
              <img className="w-full sm:w-auto" src={PromaidBanner} alt="promaid-banner" />
            </div>
            <div className="w-full px-4 pt-4 md:pt-6">
              <div className="flex flex-col sm:grid sm:grid-cols-2">
                <div className="my-2 text-sm text-gray-300 md:text-2xl md:pl-20" onClick={() => onClickMobileMenu(Menus.SERVICE, true)}>
                  บริการทำความสะอาด
                </div>
                <div className="my-2 text-sm text-gray-300 md:text-2xl md:pl-12" onClick={() => onClickMobileMenu(Menus.DISINFECTION)}>
                  บริการฉีดพ่นฆ่าเชื้อ
                </div>
                <div className="my-2 text-sm text-gray-300 md:text-2xl md:pl-20" onClick={() => onClickMobileMenu(Menus.EQUIPMENT)}>
                  ชุดอุปกรณ์ฉีดพ่นฆ่าเชื้อ
                </div>
              </div>
            </div>
            <div className="items-center justify-center hidden sm:flex ">
              <div className="hidden w-16 h-16 sm:block">
                <div
                  onClick={() => {
                    window.open('https://bit.ly/2Pln3bV', '_blank')
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <img className="cursor-pointer button-line md:flex" src={Line} alt="line icon" />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end justify-around m-6 md:m-8 sm:justify-start">
              <div className="w-6 h-6" onClick={() => setIsMenuExpanded(!isMenuExpanded)}>
                <img className="w-12 text-white sm:w-6 md:w-8" src={XIcon} alt="hamburger" />
              </div>
              <div className="block w-12 h-12 sm:hidden">
                <div
                  onClick={() => {
                    window.open('https://bit.ly/2Pln3bV', '_blank')
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <img className="cursor-pointer button-line md:flex" src={Line} alt="line icon" />
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  )
}

export default Navbar
