import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

const FBMessenger = () => {
  return (
    <>
      <div id="fb-root" />
      <Helmet>
        <script>{`
          window.fbAsyncInit = function() {
            window.FB.init({
              xfbml: true,
              version: 'v10.0'
            });
        };
        (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/th_TH/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      `}</script>
      </Helmet>
      <div className="fb-customerchat" attribution="setup_tool" page_id="535073706575640" />
    </>
  )
}

export default FBMessenger
