export function scrollToOffset(name: string, offset: number = 0) {
  if (typeof window !== 'undefined' && window && document) {
    const element = document.getElementById(name);
    if (element) {
      console.log('elem height', element.getBoundingClientRect().top + window.pageYOffset);
      console.log('offset', offset);
      const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}

export function scrollTop(offset: number = 0) {
  if (typeof window !== 'undefined') {
    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }
}
