import React from 'react'
import styled from '@emotion/styled'
import { Menus, onClickMenu } from './NavBar'
import BookingButton from '../common/BookingButton'
import CoverImage from '../../assets/images/promaid/bg-cover.jpg'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 750px;
  @media (max-width: 1920px) {
    height: 700px;
  }
  @media (max-width: 1537px) {
    height: 600px;
  }
  @media (max-width: 1280px) {
    height: 500px;
  }
  @media (max-width: 1024px) {
    height: 400px;
  }
  @media (max-width: 768px) {
    height: 350px;
  }
  @media (max-width: 640px) {
    height: 250px;
  }
  @media (max-width: 400px) {
    height: 220px;
  }
`

const Gradient = styled.div`
  position: absolute;
  background: linear-gradient(90deg, #f2fcfe 12.41%, #f2fcfe 51.92%, rgba(242, 252, 254, 0) 85.78%);
  height: 100%;
  width: 90%;
  @media (min-width: 1537px) {
    width: 100%;
  }
`

const Title = styled.h1`
  font-weight: 400;
  font-size: 72px;
  line-height: 85px;
  @media (max-width: 1024px) {
    font-size: 56px;
    line-height: 60px;
    /* margin-top: -200px; */
  }
  @media (max-width: 768px) {
    line-height: 50px;
    font-size: 42px;
    /* margin-top: -200px; */
  }
  @media (max-width: 640px) {
    font-size: 24px;
    line-height: 30px;
    /* margin-top: -200px; */
  }
`

const Subtitle = styled.p`
  line-height: 60px;
  font-size: 32px;
  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 50px;
  }
  @media (max-width: 768px) {
    line-height: 30px;
    font-size: 18px;
  }
  @media (max-width: 600px) {
    /* line-height: 20px; */
    font-size: 14px;
  }
`

const Cover: React.FC = () => {
  return (
    <>
      <Container id={Menus.SERVICE}>
        <img className="absolute top-0 right-0 h-full" src={CoverImage} alt="cover" />
        <Gradient />
        <div className="relative flex justify-center w-8/12 h-full">
          <div className="flex h-full pl-2 mt-6 md:mt-8 md:pl-4">
            <div className="flex items-center">
              <div className="">
                <Title className="leading-snug text-primary sm:mb-1 xl:mb-2">
                  ให้วันหยุด เป็นวัน <br /> พักผ่อนของคุณ
                </Title>
                <Subtitle className="py-2 font-light leading-none text-gray-200 sm:mb-0 md:mb-4 xl:mb-6 sm:pb-0 w100 text-md md:text-2xl lg:text-3xl">
                  <p className="leading-tight">จองแม่บ้านง่ายๆ ไม่ต้องโหลดแอพ</p>
                  <p className="leading-tight">พร้อมบริการฉีดพ่นฆ่าเชื้อ</p>
                </Subtitle>
                <BookingButton />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Cover
