/* eslint-disable @typescript-eslint/interface-name-prefix */
// Slider mobile breakpoint is at 1200px
import React, { useRef, useMemo, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
// import getStaticPath from '../../utils/static-path';
import NextLeft from '../../assets/images/promaid/icons/next-left.svg'
import NextRight from '../../assets/images/promaid/icons/next-right.svg'

const SliderContainer = styled.div`
  overflow: hidden;
  margin-left: -8px;
  margin-right: -8px;
  padding: 8px 0;
  @media (max-width: 1200px) {
    overflow: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
`

const Slider = styled.div<{ count: number; scrollSize: number; mobileSize: number; tabletSize: number }>`
  display: grid;
  align-self: center;
  grid-template-columns: repeat(${props => props.count}, ${props => 100 / props.scrollSize}%);
  grid-auto-flow: column;
  transform: translate(0%);
  transition: transform 0.5s ease 0s;
  > * {
    padding: 0 8px;
  }
  @media (max-width: 1200px) and (min-width: 768px) {
    overflow: auto;
    grid-template-columns: repeat(${props => props.count}, ${props => props.tabletSize}%);
    transform: translate(0%) !important;
  }
  @media (max-width: 767px) {
    overflow: auto;
    grid-template-columns: repeat(${props => props.count}, ${props => props.mobileSize}%);
    transform: translate(0%) !important;
  }
`

const OuterContainer = styled.div`
  position: relative;
`

const moveLeft = keyframes`
  0% {
    left: -48px;
  }
  24% {
    left: -56px;
  }
  100% {
    left: -48px;
  }
`

const Left = styled.a`
  position: absolute;
  left: -48px;
  top: 0;
  display: none;
  height: 100%;
  width: 40px;
  cursor: pointer;
  &:hover {
    animation: ${moveLeft} 1s infinite;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`

const moveRight = keyframes`
  0% {
    right: -48px;
  }
  24% {
    right: -56px;
  }
  100% {
    right: -48px;
  }
`

const Right = styled(Left)`
  left: unset;
  right: -50px;
  &:hover {
    animation: ${moveRight} 1s infinite;
  }
  @media (max-width: 1200px) {
    display: none !important;
  }
`

const Icon = styled.img`
  /* border: solid ${props => props.theme.black};
  border-width: 0 3px 3px 0; */
  display: block;
  padding: 3px;
  /* transform: rotate(90deg); */
  width: 45px;
  height: 45px;
  position: absolute;
  top: 45%;
`

interface IProps {
  scrollSize?: number
  mobileSize?: number
  tabletSize?: number
  className?: string
}

/* eslint-disable no-param-reassign */
function useSlider(
  sliderRef: React.MutableRefObject<HTMLElement>,
  leftArrowRef: React.MutableRefObject<HTMLElement>,
  rightArrowRef: React.MutableRefObject<HTMLElement>,
  childCount: number,
  scrollSize: number
) {
  const x = useRef(0)
  const maxScroll = useMemo(() => {
    if (childCount <= scrollSize) return 0
    const ratio = ((childCount / scrollSize) * 10 - 10) / 10
    return 100 * ratio
  }, [scrollSize, childCount])
  function updateArrowDisplay() {
    if (x.current === 0) {
      leftArrowRef.current.style.display = 'none'
    } else {
      leftArrowRef.current.style.display = 'block'
    }
    if (Math.abs(x.current).toPrecision(2) === maxScroll.toPrecision(2)) {
      rightArrowRef.current.style.display = 'none'
    } else {
      rightArrowRef.current.style.display = 'block'
    }
  }
  function updateRotate() {
    sliderRef.current.style.transform = `translate(${x.current}%)`
    updateArrowDisplay()
  }
  function updateX(direction: string) {
    const leftMostIndex = scrollSize * (Math.abs(x.current) / 100)
    const rightMostIndex = leftMostIndex + scrollSize - 1 < childCount - 1 ? leftMostIndex + scrollSize - 1 : childCount - 1
    let rotateSize = 0
    if (direction === 'left') {
      if (leftMostIndex - scrollSize >= 0) {
        rotateSize = leftMostIndex + scrollSize
        rotateSize -= leftMostIndex
      } else {
        rotateSize = leftMostIndex
      }
    } else if (direction === 'right') {
      if (rightMostIndex + scrollSize < childCount - 1) {
        rotateSize = rightMostIndex + scrollSize
      } else {
        rotateSize = childCount - 1
      }
      rotateSize -= rightMostIndex
    }
    rotateSize *= 100 / scrollSize
    rotateSize *= direction === 'left' ? 1 : -1
    x.current += rotateSize
    updateRotate()
  }
  function onClickLeft() {
    updateX('left')
  }
  function onClickRight() {
    updateX('right')
  }
  useEffect(() => {
    updateArrowDisplay()
  }, []); // eslint-disable-line
  return { onClickLeft, onClickRight }
}
/* eslint-enable no-param-reassign */

const SliderComponent: React.FC<IProps> = props => {
  const { children, scrollSize = 3, mobileSize = 80, tabletSize = 30, className } = props
  const childCount = React.Children.count(children)
  const sliderRef = useRef<HTMLElement>()
  const leftArrowRef = useRef<HTMLElement>()
  const rightArrowRef = useRef<HTMLElement>()
  const { onClickLeft, onClickRight } = useSlider(sliderRef, leftArrowRef, rightArrowRef, childCount, scrollSize)
  return (
    <OuterContainer className={className}>
      <Left ref={leftArrowRef} onClick={onClickLeft}>
        <Icon src={NextLeft} />
        {/* <img src={NextLeft} alt="next left" /> */}
      </Left>
      <SliderContainer>
        <Slider
          className="slider-ref"
          mobileSize={mobileSize}
          tabletSize={tabletSize}
          scrollSize={scrollSize}
          ref={sliderRef}
          count={React.Children.count(children)}
        >
          {children}
        </Slider>
      </SliderContainer>
      <Right ref={rightArrowRef} onClick={onClickRight}>
        <Icon src={NextRight} />
        {/* <img src={NextRight} alt="next right" /> */}
      </Right>
    </OuterContainer>
  )
}

export default SliderComponent
