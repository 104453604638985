/* eslint-disable @typescript-eslint/interface-name-prefix */
import classNames from 'classnames'
import React, { useRef } from 'react'
import styled from 'styled-components'

import Logo from '../../assets/images/promaid/avatar.png'
import StarRange from '../../assets/images/promaid/icons/star.svg'

const Container = styled.div`
  position: relative;
  border-radius: 8px;
  padding: 20px 22px;
  /* width: 100%; */
  /* width: 345px; */
  /* height: 181px; */
  height: 100%;
  margin: auto 0;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  /* @media (max-width: 1366px) {
    width: 400px;
  } */
  /* @media (max-width: 1024px) {
    width: 345px;
  }
  @media (max-width: 600px) {
    padding: 20px 30px;
    width: 300px;
  } */
`
const Row = styled.div``

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`

const Avatar = styled.div`
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 80%;
  margin-right: 14px;

  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
  }
`

const TextName = styled.h4.attrs({
  className: 'font-paragraph'
})`
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  color: #5c5c69;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const SubName = styled.p.attrs({
  className: 'font-paragraph'
})`
  font-size: 14px;
  line-height: 20px;
  color: #aeacac;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const TypeName = styled.p.attrs({
  className: 'font-paragraph'
})`
  font-size: 14px;
  line-height: 20px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const Text = styled.p.attrs({
  className: 'font-paragraph'
})`
  font-family: 'Sarabun', sans-serif;
  color: #5c5c69;
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 300;
  font-family: 'Sarabun';
  text-align: center;
  word-wrap: break-word;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const Stars = styled.div`
  height: 14px;
  position: absolute;
  right: 30px;
  top: 30px;

  @media (max-width: 600px) {
    height: 14px;
    * {
      font-size: 1.2rem;
    }
    position: relative;
    right: 0px;
    top: 10px;
    text-align: center;
  }
`
interface IProps {
  review: {
    name: string
    text: any
    score: number
    // logo: string
    subname: string
    type: string
  }
}

const ReviewCard: React.FC<IProps> = props => {
  const { review } = props
  return (
    <Container>
      <Row>
        <UserInfo>
          <Avatar>
            <img src={Logo} alt="avatar" />
          </Avatar>
          <div>
            <TextName>{review.name}</TextName>
            <SubName>{review.subname}</SubName>
            <TypeName className={classNames(review.type === 'บริการทำความสะอาด' ? 'text-primary' : 'text-secondary')}>
              {review.type}
            </TypeName>
          </div>
        </UserInfo>
        <Stars>
          <div className="flex flex-row">
            <img src={StarRange} alt="star" />
            <img src={StarRange} alt="star" />
            <img src={StarRange} alt="star" />
            <img src={StarRange} alt="star" />
            <img src={StarRange} alt="star" />
          </div>
        </Stars>
      </Row>
      <Text>
        <span>{review.text}</span>
      </Text>
    </Container>
  )
}

export default ReviewCard
