/* eslint-disable @typescript-eslint/interface-name-prefix */
import classNames from 'classnames'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import NewPromo from '../../assets/images/promaid/icons/new-promo.svg'

const Container = styled.div`
  position: relative;
  margin: auto 0;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  scroll-snap-align: start;
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
    opacity: 0.85;
  }
`

const ListHomeButton = styled.button`
  ${tw`px-1 py-1 border border-transparent rounded-full`}
  background: #E5E5E5;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 13px;
  @media (max-width: 768px) {
    font-size: 11px;
  }
`

const SpecialListHomeButton = styled.button`
  ${tw`px-1 py-1 border border-transparent rounded-full`}
  width: 100%;
  background: #e5e5e5;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
  @media (max-width: 768px) {
    font-size: 9px;
  }
`

const SpacialContainer = styled.div`
  display: none;
  position: absolute;
  width: 101px;
  margin-top: -25px;
  margin-left: -25px;
  .spacial-shadow-container {
    background: #6cbd45;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 0px 100px 100px 0px;
  }
  @media (max-width: 1024px) {
    width: 70px;
    margin-top: -15px;
    margin-left: -15px;
  }
`

const defaultTheme = styled.div``

const PromoTextColor = {
  color: '#61B2CD'
}

const LightTextColor = {
  color: '#61B2CD'
}

const DarkTextColor = {
  color: '#0179BA'
}

const CleanTextColor = {
  color: '#61B2CD'
}

const PromoBgbutton = {
  background: '#FC0909'
}

const LightBgbutton = {
  background: '#61B2CD'
}

const DarkBgbutton = {
  background: '#0179BA'
}

const CleanBgbutton = {
  display: 'none'
}

const SpacialPromo = {
  display: 'inline'
}

const NolmalPacket = {
  display: 'none'
}

const InActivePacket = {
  display: 'none'
}

const ActivePacket = {
  display: 'inline'
}

interface IProps {
  packet: {
    title: string
    name: string
    description: string
    price: string
    logo: string
    theme: string
    detail: string
  }
}

const PacketsCard: React.FC<IProps> = props => {
  const { packet } = props
  let showSpacialPromo = defaultTheme
  let selectTextColor = defaultTheme
  let selectBgbutton = defaultTheme
  let showDetailPromoCard = defaultTheme
  let showDetailCard = defaultTheme
  let showDetailSpecialPromoCard = defaultTheme
  let showList = defaultTheme
  let showTitleService = defaultTheme
  let selectBTdetail = defaultTheme
  let showListSp = InActivePacket
  if (packet.theme === 'special-promo') {
    selectTextColor = PromoTextColor
    selectBgbutton = PromoBgbutton
    showSpacialPromo = SpacialPromo
    showDetailPromoCard = InActivePacket
    showDetailCard = InActivePacket
    showDetailSpecialPromoCard = ActivePacket
    showList = InActivePacket
    showTitleService = InActivePacket
    selectBTdetail = InActivePacket
  } else if (packet.theme === 'promo') {
    selectTextColor = PromoTextColor
    selectBgbutton = PromoBgbutton
    showSpacialPromo = SpacialPromo
    showDetailPromoCard = ActivePacket
    showDetailCard = InActivePacket
    showDetailSpecialPromoCard = InActivePacket
    showList = InActivePacket
    showTitleService = InActivePacket
    selectBTdetail = InActivePacket
  } else if (packet.theme === 'light') {
    selectTextColor = LightTextColor
    selectBgbutton = LightBgbutton
    showSpacialPromo = NolmalPacket
    showDetailPromoCard = InActivePacket
    showDetailCard = ActivePacket
    showDetailSpecialPromoCard = InActivePacket
    showList = InActivePacket
    showTitleService = InActivePacket
    selectBTdetail = InActivePacket
  } else if (packet.theme === 'dark') {
    selectTextColor = DarkTextColor
    selectBgbutton = DarkBgbutton
    showSpacialPromo = NolmalPacket
    showDetailPromoCard = InActivePacket
    showDetailCard = ActivePacket
    showDetailSpecialPromoCard = InActivePacket
    showList = InActivePacket
    showTitleService = InActivePacket
    selectBTdetail = InActivePacket
  } else if (packet.theme === 'clean') {
    selectTextColor = InActivePacket
    selectBgbutton = CleanBgbutton
    showSpacialPromo = NolmalPacket
    showDetailPromoCard = InActivePacket
    showDetailCard = InActivePacket
    showDetailSpecialPromoCard = InActivePacket
    showList = ActivePacket
    showTitleService = ActivePacket
    selectBTdetail = ActivePacket
  } else if (packet.theme === 'sp') {
    selectTextColor = InActivePacket
    selectBgbutton = PromoBgbutton
    showSpacialPromo = SpacialPromo
    showDetailPromoCard = InActivePacket
    showDetailCard = InActivePacket
    showDetailSpecialPromoCard = InActivePacket
    showList = InActivePacket
    showTitleService = ActivePacket
    selectBTdetail = InActivePacket
    showListSp = ActivePacket
  }
  return (
    <Container>
      <div className="flex flex-col w-full gap-1 bg-white border-white rounded-lg button-line justify-self-center">
        <div className="flex-shrink-0 block mb-3 button-line">
          <img className="w-full rounded-t-lg button-line" src={packet.logo} alt="packet promo" />
        </div>
        <SpacialContainer className="button-line" style={showSpacialPromo}>
          <img className="w-full rounded-t-lg button-line" src={NewPromo} alt="new promo" />
        </SpacialContainer>
        <div className="flex flex-col justify-center my-1 text-center button-line lg:my-2 xl:my-4">
          <div className="flex flex-col justify-center h-16 -mt-4 text-sm button-line md:text-base lg:text-lg">
            <div className="button-line" style={selectTextColor}>
              {packet.title}
            </div>
            <p className="pb-2 text-lg button-line md:text-xl lg:text-2xl" style={selectTextColor}>
              {packet.name}
            </p>
            <div className="text-base text-blue-200 button-line md:text-lg lg:text-xl" style={showTitleService}>
              <p className="-mt-3 xl:-mt-4 button-line">{packet.title}</p>
              <p className="button-line">{packet.name}</p>
            </div>
          </div>
          <p className="text-xs button-line lg:text-sm xl:text-sm">{packet.description}</p>
        </div>
        <ListHomeButton style={showDetailPromoCard}>
          <div className="flex flex-row justify-around px-2 button-line">
            <p className="font-light button-line">คอนโด</p>
            <p className="font-light button-line">|</p>
            <p className="font-light button-line">อพาร์ทเม้นท์</p>
            <p className="font-light button-line">|</p>
            <p className="font-light button-line">ทาวน์โฮม</p>
          </div>
        </ListHomeButton>
        <ListHomeButton style={showDetailCard}>
          <div className="flex flex-row justify-around px-1 button-line">
            <p className="font-light button-line">บ้าน</p>
            <p className="font-light button-line">|</p>
            <p className="font-light button-line">คอนโด</p>
            <p className="font-light button-line">|</p>
            <p className="font-light button-line">อพาร์ทเม้นท์</p>
            <p className="font-light button-line">|</p>
            <p className="font-light button-line">ทาวน์โฮม</p>
          </div>
        </ListHomeButton>
        <div className="flex flex-col -mt-4 button-line" style={showDetailSpecialPromoCard}>
          <div className="flex justify-center button-line">
            <SpecialListHomeButton>
              <div className="flex flex-row justify-around px-2 button-line">
                <p className="font-light button-line">บ้าน</p>
                <p className="font-light button-line">|</p>
                <p className="font-light button-line">คอนโด</p>
                <p className="font-light button-line">|</p>
                <p className="font-light button-line">อพาร์ทเม้นท์</p>
                <p className="font-light button-line">|</p>
                <p className="font-light button-line">ทาวน์โฮม</p>
                <p className="font-light button-line">|</p>
                <p className="font-light button-line">ออฟฟิศ</p>
              </div>
            </SpecialListHomeButton>
          </div>
          <div className="flex justify-center button-line">
            <p className="mt-4 -mb-2 text-base font-normal button-line lg:text-lg xl:text-lg">{packet.detail}</p>
          </div>
        </div>
        <div className="flex flex-col -my-1 text-xs lg:-my-2 xl:-my-2 button-line lg:text-sm xl:text-sm" style={showList}>
          <div className="flex justify-center pl-4 -mt-4 -mb-3 lg:-mt-3 lg:-mb-2 button-line">
            <ul className="list-disc button-line">
              <li className="text-xs leading-relaxed lg:text-sm button-line">เครื่องพ่นละอองฝอย 1 เครื่อง</li>
              <li className="text-xs leading-relaxed lg:text-sm button-line">น้ำยาฆ่าเชื้อ 2 แกลลอน (รวม 4L)</li>
              <li className="text-xs leading-relaxed lg:text-sm button-line">เครื่องพ่นฆ่าเชื้อพกพา 10 เซต</li>
              <li className="text-xs leading-relaxed lg:text-sm button-line">ชุด PPE 2 ชุด</li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col -mt-1 -mb-2 text-xs lg:-mb-3 button-line lg:text-sm xl:text-sm" style={showListSp}>
          <div className="flex justify-center pl-4 -mt-4 -mb-3 lg:-mt-3 lg:-mb-2 button-line">
            <ul className="list-disc button-line">
              <li className="text-xs leading-relaxed lg:text-sm button-line">เครื่องพ่นน้ำยาฆ่าเชื้อไร้สาย 1 เครื่อง</li>
              <li className="text-xs leading-relaxed lg:text-sm button-line">น้ำยาฆ่าเชื้อ 500 ml</li>
              <li className="text-xs leading-relaxed lg:text-sm button-line">เครื่องพ่นนาโนฆ่าเชื้อพกพา 1 เซต</li>
              <li className="text-xs leading-relaxed lg:text-sm button-line">กระบอก Syringe ขนาด 5 ml 1 ชิ้น</li>
            </ul>
          </div>
        </div>
        <div className="mt-6" />
        <div className="py-2 text-center border border-transparent button-line rounded-b-md" style={selectBgbutton}>
          <p className="text-xl text-white button-line">
            <span className="text-3xl font-medium button-line">{packet.price} </span>
            บาท
          </p>
        </div>
        <div className="py-2 text-center border border-transparent button-line bg-secondary rounded-b-md" style={selectBTdetail}>
          <p className="pb-1 text-xl text-white text-bold button-line md:text-2xl">
            <span className="text-base font-light button-line md:text-xl">สอบถามเพิ่มเติม </span>
            คลิก !
          </p>
        </div>
      </div>
    </Container>
  )
}

export default PacketsCard
